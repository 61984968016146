
/**
 * Blue palette.
 */

const bluePalette = {
  blue100: '#D4DEFF',
  blue200: '#B8C4F1',
  blue300: '#7797F5',
  blue350: '#6b7ad2',
  blue400: '#6B88DD',
  blue500: '#687FD0',
  blue600: '#3D4BA1',
  blue700: '#212573',
  blue800: '#01103D',
  blue900: '#010d31',
  blue1000: '#010b2b'
};

/**
 * Green palette.
 */

const greenPalette = {
  green200: '#9EF6E8',
  green300: '#6CEDCF',
  green400: '#39d9b9',
  green500: '#00E8C1'
};

/**
 * Grey palette.
 */

const greyPalette = {
  grey100: '#E9EEFD',
  grey200: '#E2E8F0',
  grey300: '#CBD5E0',
  grey500: '#A0AEC0',
  grey700: '#718096',
  grey800: '#2D3748'
};

/**
 * Brown palette.
 */

const brownPalette = {
  brown500: '#979797'
};

/**
 * Red palette.
 */

const redPalette = {
  error400: '#fd7171'
};

/**
 * Yellow palette.
 */

const yellowPalette = {
  yellow500: '#ffb637'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...bluePalette,
  ...brownPalette,
  ...greyPalette,
  ...greenPalette,
  ...redPalette,
  ...yellowPalette
};
