// @flow

/**
 * Module dependencies.
 */

import { ThemeProvider } from 'styled-components';
import React, { type Node } from 'react';
import theme from 'styles/theme';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Root` component.
 */

function Root({ children }: Props): Node {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

/**
 * Export `Root` component.
 */

export default Root;
