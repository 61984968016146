// @flow

/**
 * Module dependencies.
 */

import { keyframes } from 'styled-components';

/**
 * `fadeIn` keyframes.
 */

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * Scale animation.
 */

const scaleAnimation = keyframes`
  from {
    transform: scaleX(0);
    transform-origin: left;
  }

  to {
    transform: scaleX(100%);
    transform-origin: left;
  }
`;

/**
 * Shake.
 */

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

/**
 * Spin.
 */

const spin = keyframes`
  0% {
    transform: rotate(0turn);
  }

  50% {
    transform: rotate(1turn);
  }

  100% {
    transform: rotate(1turn);
  }
`;

/**
 * Export `keyframes`.
 */

export default {
  fadeIn,
  fadeOut,
  scaleAnimation,
  shake,
  spin
};
