// @flow

/**
 * Export `transitions`.
 */

export const transitions = {
  default: '500ms ease',
  fast: '200ms ease',
  slow: '650ms ease'
};
