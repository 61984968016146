// @flow

/**
 * Module dependencies.
 */

import { boxShadows } from './box-shadows';
import { colors } from './colors';
import { createTheme } from 'react-components/styles';
import { transitions } from './transitions';
import { typography } from './type';
import { zIndex } from './z-index';
import keyframes from './keyframes';

/**
 * Export `theme`.
 */

export default createTheme({
  boxShadows,
  colors,
  containerMaxWidth: '1650px',
  keyframes,
  transitions,
  typography,
  zIndex
});
