// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-cms-templates-about-us-js": () => import("./../../../src/cms-templates/about-us.js" /* webpackChunkName: "component---src-cms-templates-about-us-js" */),
  "component---src-cms-templates-home-js": () => import("./../../../src/cms-templates/home.js" /* webpackChunkName: "component---src-cms-templates-home-js" */),
  "component---src-cms-templates-join-js": () => import("./../../../src/cms-templates/join.js" /* webpackChunkName: "component---src-cms-templates-join-js" */),
  "component---src-cms-templates-richtext-js": () => import("./../../../src/cms-templates/richtext.js" /* webpackChunkName: "component---src-cms-templates-richtext-js" */),
  "component---src-cms-templates-roadmap-js": () => import("./../../../src/cms-templates/roadmap.js" /* webpackChunkName: "component---src-cms-templates-roadmap-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

