
/**
 * Module dependencies.
 */

import 'firebase/analytics';
import React from 'react';
import Root from 'components/root';

/**
 * Export `wrapRootElement`.
 */

export function wrapRootElement({ element }) {
  return (
    <Root>
      {element}
    </Root>
  );
}
