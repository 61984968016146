// @flow

/**
 * Module dependencies.
 */

import { css } from 'styled-components';
import { setFontStyle } from 'styles/utils/typography';

/**
 * Export `fontFamily`.
 */

export const fontFamily = {
  sanSerif: 'Montserrat, sans-serif'
};

/**
 * Base styles.
 */

const fontSizeBase = 15;
const lineHeightBase = 24;

/**
 * Export `typeSizes`.
 */

export const typeSizes = {
  h1: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 61,
    fontSizeMax: 61,
    fontSizeMin: 38,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 72
  },
  h2: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 49,
    fontSizeMax: 49,
    fontSizeMin: 34,
    fontWeight: 600,
    letterSpacing: -0.7,
    lineHeight: 48
  },
  h3: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 39,
    fontSizeMax: 39,
    fontSizeMin: 29,
    fontWeight: 600,
    letterSpacing: -0.5,
    lineHeight: 40
  },
  h4: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 27,
    fontSizeMax: 27,
    fontSizeMin: 20,
    fontWeight: 300,
    letterSpacing: -0.3,
    lineHeight: 32
  },
  h5: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 18,
    fontSizeMax: 18,
    fontSizeMin: 16,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 28
  },
  label: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 16
  },
  paragraph: {
    fontFamily: fontFamily.sanSerif,
    fontSize: fontSizeBase,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: lineHeightBase
  },
  small: {
    fontFamily: fontFamily.sanSerif,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 20
  }
};

/**
 * `h1` style.
 */

const h1 = css`
  ${setFontStyle(typeSizes.h1)}
`;

/**
 * `h2` style.
 */

const h2 = css`
  ${setFontStyle(typeSizes.h2)}
`;

/**
 * `h3` style.
 */

const h3 = css`
  ${setFontStyle(typeSizes.h3)}
`;

/**
 * `h4` style.
 */

const h4 = css`
  ${setFontStyle(typeSizes.h4)}
`;

/**
 * `h5` style.
 */

const h5 = css`
  ${setFontStyle(typeSizes.h5)}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  ${setFontStyle(typeSizes.paragraph)}
`;

/**
 * `small` style.
 */

const small = css`
  ${setFontStyle(typeSizes.small)}
`;

/**
 * `label` style.
 */

const label = css`
  ${setFontStyle(typeSizes.label)}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */
export const typography = {
  fontFamily,
  fontSizeBase,
  lineHeightBase,
  styles
};
